/*
 * This file is Free Software under GNU Affero General Public License v >= 3.0
 * without warranty, see README.md and license for details.
 *
 * SPDX-License-Identifier: AGPL-3.0-or-later
 *
 * SPDX-FileCopyrightText: 2020 nic.at GmbH <https://nic.at>
 * Software-Engineering: 2020 Intevation GmbH <https://intevation.de>
 *
 * Author(s):
 * 2021, 2022 Fadi Abbud <fadi.abbud@intevation.de>
 */
import { HTTP } from "../lib/http";
export const organisation = {
  namespaced: true,
  state: () => ({
    organisationTags: [],
    organisations: []
  }),
  mutations: {
    setOrganisationTags: (state, tags) => {
      state.organisationTags = tags;
    },
    setOrganisations: (state, organisaitons) => {
      state.organisations = organisaitons;
    }
  },
  actions: {
    loadOrganisationTags({ commit }) {
      return new Promise((resolve, reject) => {
        HTTP.get("/organisation_tags")
          .then(response => {
            commit("setOrganisationTags", response.data);
            resolve(response);
          })
          .catch(error => reject(error));
      });
    }
  }
};
