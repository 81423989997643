/*
 * This file is Free Software under GNU Affero General Public License v >= 3.0
 * without warranty, see README.md and license for details.
 *
 * SPDX-License-Identifier: AGPL-3.0-or-later
 *
 * SPDX-FileCopyrightText: 2020 nic.at GmbH <https://nic.at>
 * Software-Engineering: 2020 Intevation GmbH <https://intevation.de>
 *
 * Author(s):
 * * Fadi Abbud <fadi.abbud@intevation.de>
 */
import { HTTP } from "../lib/http";
export const contact = {
  namespaced: true,
  state: () => ({
    contactTags: [],
    organisationTags: [],
    abuseC: null
  }),
  mutations: {
    setContactTags: (state, tags) => {
      state.contactTags = tags;
    },
    setOrganisationTags: (state, tags) => {
      state.organisationTags = tags;
    },
    setAbuseC: (state, value) => {
      state.abuseC = value;
      // Set more properties to handle this properly in the components
      state.abuseC["label"] = "Abuse-C";
      state.abuseC["type"] = "abusec";
      state.abuseC["contact_id"] = -1;
    }
  },
  actions: {
    loadContactTags({ commit }) {
      return new Promise((resolve, reject) => {
        HTTP.get("/contact_tags")
          .then(response => {
            commit("setContactTags", response.data);
            resolve(response);
          })
          .catch(error => reject(error));
      });
    },
    loadAbuseC({ commit }, orgaID) {
      return new Promise((resolve, reject) => {
        HTTP.get(`/organisations/${orgaID}/abuse-c`)
          .then(response => {
            commit("setAbuseC", response.data);
            resolve(response);
          })
          .catch(error => {
            // Do not throw an error if there is no abuse-c
            // as this expected if the organisation has no RIPE handle
            if (error.response.status !== 404) {
              reject(error);
            }
          });
      });
    }
  }
};
