import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import VueClipboard from "vue-clipboard2";
import { suggestLightTheme } from "./lib/theme-helper";
import Keycloak from "@/plugins/keycloak";

Vue.config.productionTip = false;
Vue.use(VueClipboard);

//Fetch client config before intiating the app.
//TODO: process.env cannot be used in in main class. Find a workaround and make the config path configurable.
fetch("/resources/client-config.json")
  .then(response => response.json())
  .then(config => {
    // Make config globally available.
    Vue.prototype.$config = config;

    // Configure router titles
    router.afterEach((to, from) => {
      Vue.nextTick(() => {
        document.title =
          Vue.prototype.$config.client.pageMainTitle + " - " + to.name;
      });
    });

    // Use Keycloak and init.
    Vue.use(Keycloak);

    Vue.prototype.$keycloak
      .init({ onLoad: "login-required" })
      .then((authenticated: boolean) => {
        new Vue({
          router,
          store,
          vuetify,
          render: h => h(App)
        }).$mount("#app");

        /*
         * Updating color scheme in theme store when external css
         * theme background is too bright.
         */
        setTimeout(() => {
          if (document.readyState == "complete" && suggestLightTheme()) {
            store.commit("theme/enableDarkTheme", false);
          }
        }, 500); //TODO: Find a way to chain this function directly after app render.
      });
  });
