import store from "@/store";
import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Home",
    redirect: "/netobjects"
  },
  {
    path: "/claimresolution",
    name: "Claim Resolution",
    component: () => import("@/components/netobjects/ClaimResolution.vue"),
    beforeEnter: (to, from, next) => {
      // eslint-disable-next-line
      const storeState = store.state as any;
      const isAllwoed =
        ["tenantadmin", "portaladmin"].indexOf(
          storeState.user.selectedNode.level
        ) !== -1;
      if (!isAllwoed) {
        next("/netobjects");
      } else {
        next();
      }
    }
  },
  {
    path: "/netobjects",
    name: "Netobjects",
    component: () => import("@/components/netobjects/NetObjectView.vue")
  },
  {
    path: "/mynode",
    name: "My Node",
    component: () => import("@/components/Home.vue")
  },
  {
    path: "/addcolleague",
    name: "Add Colleague",
    component: () => import("@/components/addColleague.vue")
  },
  {
    path: "/organisations",
    name: "Organisations",
    component: () => import("@/components/organisations/Organisations.vue")
  },
  {
    path: "/contacts",
    name: "Contacts",
    component: () => import("@/components/contacts/Contacts.vue")
  },
  {
    path: "/tags",
    name: "Tags",
    component: () => import("@/components/systemconfiguration/TagsView.vue")
  }
];

const router = new VueRouter({
  routes
});

export default router;
