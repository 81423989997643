import { PluginObject } from "vue";
import Keycloak, { KeycloakConfig } from "keycloak-js";

const KcPlugin: PluginObject<any> = {
  install(Vue, options) {
    const initOptions: KeycloakConfig = Vue.prototype.$config.keycloak;
    Vue.prototype.$keycloak = new Keycloak(initOptions);
  }
};

export default KcPlugin;
