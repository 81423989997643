/*
 * This file is Free Software under GNU Affero General Public License v >= 3.0
 * without warranty, see README.md and license for details.
 *
 * SPDX-License-Identifier: AGPL-3.0-or-later
 *
 * SPDX-FileCopyrightText: 2020 nic.at GmbH <https://nic.at>
 * Software-Engineering: 2020 Intevation GmbH <https://intevation.de>
 *
 * Author(s):
 * * Fadi Abbud <fadi.abbud@intevation.de>
 */

export const netobject = {
  namespaced: true,
  state: () => ({
    selectedNO: {},
    showOnlyDefaultRules: false,
    organisationRules: [],
    netobjectRules: [],
    globalRules: []
  }),
  mutations: {
    setSelectedNO: (state, data) => {
      state.selectedNO = data;
    },
    setshowOnlyDefaultRules: (state, show) => {
      state.showOnlyDefaultRules = show;
    },
    setOrganisationRules: (state, data) => {
      state.organisationRules = data;
    },
    setNetobjectRules: (state, data) => {
      state.netobjectRules = data;
    },
    setGlobalRules: (state, data) => {
      state.globalRules = data;
    }
  }
};
