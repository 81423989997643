import Vue from "vue";
import Vuex from "vuex";
import { user } from "./user";
import { application } from "./application";
import { netobject } from "./netobject";
import { contact } from "./contact";
import { organisation } from "./organisation";
import { theme } from "./theme";

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    user,
    application,
    netobject,
    contact,
    organisation,
    theme
  }
});
export default store;
export const useStore = () => store;
