/*
 * This file is Free Software under GNU Affero General Public License v >= 3.0
 * without warranty, see README.md and license for details.
 *
 * SPDX-License-Identifier: AGPL-3.0-or-later
 *
 * SPDX-FileCopyrightText: 2020 nic.at GmbH <https://nic.at>
 * Software-Engineering: 2020 Intevation GmbH <https://intevation.de>
 *
 * Author(s):
 * * Fadi Abbud <fadi.abbud@intevation.de>
 */
import IPCIDR from "ip-cidr";

const getFlatView = data => {
  // Remove the last element as it is the actual organisation
  // To avoid displaying it in the table twice
  data.pop();
  return data.length > 1 ? data.join(" --> ") : data.join("");
};

const getTenantView = data => {
  return data.length ? data.join(", ") : data.join("");
};

const mapDate = date => {
  return new Date(date).toLocaleString();
};

const mapNetobjects = data => {
  const netobjects: object[] = [];
  // To make each netobject unique as each netobject has its own id-key name
  // and the ids values could be overlapped(each netobject-type has its own DB-table)
  let idForTable = 0;
  if (data && data.length) {
    data.forEach(d => {
      if (Object.keys(d).some(k => k === "asn_id")) {
        d["netobject"] = "AS" + d.asn;
        d["type"] = "asn";
        d["idfortable"] = ++idForTable;
        if (d["updated_at"]) {
          d["updated_at"] = mapDate(d["updated_at"]);
        }
        netobjects.push(d);
      }
      if (Object.keys(d).some(k => k === "network_id")) {
        d["type"] = "network";
        d["subNetobject"] = false;
        d["depth"] = 0;
        d["parent"] = 0;
        d["idfortable"] = ++idForTable;
        d["netobject"] =
          // Check if the netmask "32" for IPv4 or "128" for IPv6
          // to remove the mask representation as it is a single IP
          (!d.address.includes(":") &&
            d.address.substr(d.address.indexOf("/") + 1) == 32) ||
          (d.address.includes(":") &&
            d.address.substr(d.address.indexOf("/") + 1) == 128)
            ? d.address.slice(0, d.address.indexOf("/"))
            : d.address;
        if (d["updated_at"]) {
          d["updated_at"] = mapDate(d["updated_at"]);
        }
        netobjects.push(d);
      }
      if (Object.keys(d).some(k => k === "fqdn_id")) {
        d["netobject"] = d.fqdn;
        d["type"] = "domain";
        d["subNetobject"] = false;
        d["parent"] = 0;
        d["depth"] = 0;
        d["idfortable"] = ++idForTable;
        if (d["updated_at"]) {
          d["updated_at"] = mapDate(d["updated_at"]);
        }
        netobjects.push(d);
      }
      if (Object.keys(d).some(k => k === "ripe_org_hdl_id")) {
        d["netobject"] = d.ripe_org_hdl;
        d["type"] = "ripehandle";
        // Ripe-organisation-handle is always parent for its networks
        d["parent"] = 0;
        d["idfortable"] = ++idForTable;
        if (d["updated_at"]) {
          d["updated_at"] = mapDate(d["updated_at"]);
        }
        netobjects.push(d);
      }
      if (Object.keys(d).some(k => k === "network_automatic_id")) {
        d["idfortable"] = ++idForTable;
        d["type"] = "networkautomatic";
        d["subNetobject"] = true;
        d["depth"] = 1;
        d["netobject"] = d.address;
        if (d["updated_at"]) {
          d["updated_at"] = mapDate(d["updated_at"]);
        }
        netobjects.push(d);
      }
    });
    // Sort Network-netobjects hierarchically
    if (netobjects && netobjects.length && netobjects[0]["network_id"]) {
      netobjects.sort((a, b) => {
        // Sort CIDRs v6 netobjects after the v4 ones
        if (a["netobject"].includes(".") && b["netobject"].includes(":")) {
          return -1;
        }
        if (a["netobject"].includes(":") && b["netobject"].includes(".")) {
          return 1;
        }
        const aA = spreadCIDR(a["netobject"]);
        const bB = spreadCIDR(b["netobject"]);
        for (let i = 0; i < aA.length; i++) {
          if ((aA[i] = parseInt(aA[i])) < (bB[i] = parseInt(bB[i]))) {
            return -1;
          } else if (aA[i] > bB[i]) {
            return 1;
          }
        }
        return 0;
      });
      const hArray: string[] = [];
      const heirarchalLevel = network => {
        while (hArray.length) {
          const last = hArray[hArray.length - 1]["netobject"];
          const aIP = new IPCIDR(last);
          if (
            last !== network.netobject &&
            aIP.isValid() &&
            aIP.contains(network.netobject)
          ) {
            break;
          }
          hArray.pop();
        }
        hArray.push(network);
        return hArray.length;
      };
      for (let i = 0; i < netobjects.length; i++) {
        const level = heirarchalLevel(netobjects[i]);
        netobjects[i]["depth"] = level - 1;
        netobjects[i]["subNetobject"] = level > 1 ? true : false;
      }
    }
    if (netobjects && netobjects.length && netobjects[0]["fqdn_id"]) {
      netobjects.sort((a, b) => {
        const aA = a["netobject"].split(".");
        const bB = b["netobject"].split(".");
        let i = aA.length - 1,
          j = bB.length - 1;
        while (i >= 0 || j >= 0) {
          if (aA[i] < bB[j]) {
            return -1;
          } else if (aA[i] > bB[j]) {
            return 1;
          }
          i--;
          j--;
        }
        return aA.length < bB.length ? -1 : aA.length > bB.length ? 1 : 0;
      });
      const hArray: string[] = [];
      const hierarchalLevel = domain => {
        while (hArray.length) {
          const last = hArray[hArray.length - 1]["netobject"];
          if (domain.netobject !== last && domain.netobject.includes(last))
            break;
          hArray.pop();
        }
        hArray.push(domain);
        return hArray.length;
      };
      for (let i = 0; i < netobjects.length; i++) {
        const level = hierarchalLevel(netobjects[i]);
        netobjects[i]["depth"] = level - 1;
        netobjects[i]["subNetobject"] = level > 1 ? true : false;
      }
    }
    return netobjects;
  } else {
    return [];
  }
};
const isIpV4 = item => {
  return item.includes(".");
};
const spreadCIDR = item => {
  if (isIpV4(item)) {
    item = new IPCIDR(item.includes("/") ? item : item + "/32")
      .start()
      .split(".");
  } else {
    item = new IPCIDR(item.includes("/") ? item : item + "/128")
      .start()
      .split(":");
  }
  return item;
};
export { getFlatView, getTenantView, mapNetobjects, mapDate };
