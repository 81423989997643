/*
 * This file is Free Software under GNU Affero General Public License v >= 3.0
 * without warranty, see README.md and license for details.
 *
 * SPDX-License-Identifier: AGPL-3.0-or-later
 *
 * SPDX-FileCopyrightText: 2020 nic.at GmbH <https://nic.at>
 * Software-Engineering: 2020 Intevation GmbH <https://intevation.de>
 *
 * Author(s):
 * Fadi Abbud <fadi.abbud@intevation.de>
 */
import axios from "axios";
import store from "../store";
import keycloakUpdateToken from "@/middlewares/keycloak-update-token";

const HTTP = axios.create({
  baseURL: "/api"
});

// Apply Keycloak token middleware
HTTP.interceptors.request.use(async config => {
  const token = await keycloakUpdateToken();
  config.headers.common["Authorization"] = `Bearer ${token}`;
  return config;
});

// Cancel token for cancelling requests
const CancelToken = axios.CancelToken;
const isRequestCanceled = axios.isCancel;
HTTP.interceptors.response.use(
  response => response,
  error => {
    if (error.response) {
      if (error.response.status === 401) {
        store.commit("application/setShowErrorDialog", true);
      } else if (error.response.status === 404) {
        store.commit(
          "application/setHttpErrorMessage",
          `Page '${error.response.config.url}' not found`
        );
      } else if (error.response.status === 422) {
        if (error.response.data.errors) {
          const errorMsg = Object.values(error.response.data.errors).join(" ");
          store.commit(
            "application/setHttpErrorMessage",
            "Error 442: " + errorMsg
          );
        }
      } // User exists with the same email
      else if (error.response.status === 409) {
        store.commit(
          "application/setHttpErrorMessage",
          error.response.data.message
        );
      } else {
        store.commit(
          "application/setHttpErrorMessage",
          "Backend Error:" + " " + error.response.statusText
        );
      }
    } else if (error.request) {
      store.commit("application/setHttpErrorMessage", error.reqeuset);
    } else {
      store.commit("application/setHttpErrorMessage", "Error" + error.message);
    }
    return Promise.reject(error);
  }
);

export { HTTP, CancelToken, isRequestCanceled };
