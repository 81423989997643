/*
 * This file is Free Software under GNU Affero General Public License v >= 3.0
 * without warranty, see README.md and license for details.
 *
 * SPDX-License-Identifier: AGPL-3.0-or-later
 *
 * SPDX-FileCopyrightText: 2023 cert.at GmbH <https://cert.at>
 * Software-Engineering: 2023 Martin Haidn <https://haidn.eu>
 *
 * Author(s):
 * - Martin Haidn <martin@haidn.eu>
 */

/*
 * Reads the computed background-color property of div id "theme".
 * NOTE: The actual background color is not available until the external
 * css file is rendered and applied. Use a readystatechange event listener
 * to use this functionality properly.
 */
const getComputedThemeColorFromDom = () => {
  const fallbackColor = "rgb(0, 0, 0)";
  const themeElement = document.getElementById("theme");

  if (themeElement) {
    return getComputedStyle(themeElement).backgroundColor;
  } else {
    return fallbackColor;
  }
};

const rgbToHex = rgb => {
  const rgbRegex = /^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/;
  return `${rgb
    .match(rgbRegex)
    .slice(1)
    .map(n =>
      parseInt(n, 10)
        .toString(16)
        .padStart(2, "0")
    )
    .join("")}`;
};

const getThemeColorRgb = () => {
  const rgbValues = getComputedThemeColorFromDom()
    .replace(/[^\d,]/g, "")
    .split(",");
  return rgbValues.map(value => parseInt(value));
};

const getThemeColorHex = () => {
  return rgbToHex(getComputedThemeColorFromDom());
};

/*
 * Calculates if externally provided background color for #theme has enough
 * contrast for the default dark theme. (White text colour)
 */
const suggestLightTheme = () => {
  const themeColor = getThemeColorRgb();
  const contrastReference =
    themeColor[0] * 0.299 + themeColor[1] * 0.587 + themeColor[2] * 0.114;

  return contrastReference > 186;
};

export { getThemeColorHex, getThemeColorRgb, suggestLightTheme };
