/*
 * This file is Free Software under GNU Affero General Public License v >= 3.0
 * without warranty, see README.md and license for details.
 *
 * SPDX-License-Identifier: AGPL-3.0-or-later
 *
 * SPDX-FileCopyrightText: 2023 cert.at GmbH <https://cert.at>
 * Software-Engineering: 2023 Martin Haidn <https://haidn.eu>
 *
 * Author(s):
 * - Martin Haidn <martin@haidn.eu>
 */
export const theme = {
  namespaced: true,
  state: () => ({
    darkThemeEnabled: true,
    textClass: ["white--text"]
  }),
  mutations: {
    enableDarkTheme: (state, enabled) => {
      state.darkThemeEnabled = enabled;
      state.textClass = enabled
        ? ["white--text"]
        : ["grey--text", "text--darken-3"];
    }
  },
  getters: {
    darkThemeEnabled: state => {
      return state.darkThemeEnabled;
    },
    getTextClass: state => {
      return state.textClass;
    }
  }
};
